@import "src/core/common/assets/styles/mixins";

.root {
  @include forceTheme(l);

  max-width: 1206px;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 80px;

  @include respondTo(l) {
    margin-bottom: 100px;
  }
}

.content {
  padding-left: 15px;
  padding-right: 15px;

  @include respondTo(l) {
    padding-left: 20px;
    padding-right: 20px;
  }
}

.bonusesList {
  display: flex;
  flex-direction: column;
  max-width: 460px;
  margin-left: auto;
  margin-right: auto;

  @include respondTo(l) {
    flex-direction: row;
    max-width: none;
    margin-left: -20px;
    margin-right: -20px;
  }
}

.bonusesItem {
  width: 100%;
  display: flex;
  margin-bottom: 20px;

  @include respondTo(l) {
    margin-bottom: 0;
    width: 28%;
    padding-left: 20px;
    padding-right: 20px;
  }

  &:last-child {
    margin-bottom: 0;
  }
}

.rewardsItem {
  @include respondTo(l) {
    flex: 1;
  }
}

.bonusesItemInner {
  display: flex;
  flex-direction: column;
  border-radius: 12px;
  overflow: hidden;
  width: 100%;
}

.itemImage {
  position: relative;
  background-color: var(--primary-100);

  @include forceTheme(d);
  @include positionFutureImage();
}

.itemImageDesktop {
  min-height: 116px;

  @include hideOnMobile();
}

.itemImageMobile {
  min-height: 62px;

  @include hideOnDesktop();
}

.itemContent {
  background-color: var(--primary-900);
  padding: 16px 20px;
  flex: 1;

  @include respondTo(l) {
    padding: 44px 20px 26px;
  }
}

.itemTitle {
  font-weight: 800;
  font-size: 28px;
  line-height: 38px;
  color: var(--primary-300);
  margin-bottom: 4px;
  text-align: center;

  @include respondTo(l) {
    font-size: 44px;
    line-height: 60px;
    margin-bottom: 12px;
  }
}

.itemSubtitle {
  font-size: 14px;
  line-height: 150%;
  color: var(--typography-100);
  text-align: center;

  @include respondTo(l) {
    font-size: 18px;
  }
}

.divider {
  background-color: var(--secondary-800);
  width: 100%;
  height: 1px;
  margin-bottom: 12px;
  margin-top: 12px;

  @include respondTo(l) {
    margin-bottom: 16px;
  }
}

.perksItem {
  color: var(--typography-100);
  font-weight: 700;
  font-size: 14px;
  line-height: 21px;
  margin-bottom: 8px;
  padding-left: 24px;
  position: relative;

  &::before {
    content: '';
    background-image: url('/images/star.svg');
    background-repeat: no-repeat;
    background-position: center;
    background-size: 16px;
  }

  @include respondTo(l) {
    font-size: 16px;
    line-height: 22px;
    padding-left: 36px;

    &::before {
      width: 20px;
      height: 20px;
      background-size: 20px;
    }
  }

  &:last-child {
    margin-bottom: 0;
  }
}

.icon {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  width: 20px;
  height: 20px;
  fill: var(--star);
}
