@import "src/core/common/assets/styles/mixins";

.root {
  background: var(--white-with-opacity-5);
  margin-left: 7px;
  margin-right: 7px;
  border-radius: 20px;
}

.content {
  @include pageContainerMaxWidth();

  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 32px 7px;

  @include respondTo(l) {
    padding: 48px 100px;
  }
}

.title {
  text-align: center;
  font-weight: 700;
  font-size: 24px;
  line-height: 32px;
  color: var(--typography-100);
  margin-bottom: 13px;

  @include respondTo(l) {
    font-size: 40px;
    line-height: 54px;
  }
}

.subtitle {
  text-align: center;
  font-size: 14px;
  line-height: 150%;
  color: var(--typography-200);
  margin-bottom: 32px;

  @include respondTo(l) {
    font-size: 16px;
  }
}

.expertListWrapper {
  max-width: 360px;
  margin-bottom: 30px;
  width: 100%;

  @include respondTo(l) {
    max-width: none;
    margin-bottom: 40px;
  }
}

.expertList {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: -17px;
  margin-right: -8px;

  @include respondTo(l) {
    margin-right: -24px;
  }
}

.loaderWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}

.expertListItem {
  flex: 0 0 auto;
  width: 50%;
  max-width: 100%;
  padding-bottom: 17px;
  padding-right: 8px;

  @include respondTo(l) {
    width: calc(100% / 3);
    padding-right: 24px;
  }
}

.expert {
  @include forceTheme(d);

  height: 100%;
}

.moreControlWrapper {
  margin-top: 0;
  margin-bottom: 0;
}

.moreExperts {
  margin-right: 40px;

  @include positionFutureImage(539px);
  @include hideOnMobile();
}

.moreControl {
  color: var(--primary);
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
}

.moreControlIcon {
  margin-right: -10px;
  margin-left: 20px;
}

.more {
  display: flex;
  align-items: center;
}

.icon {
  width: 24px;
  height: 24px;
  color: currentColor;
}