/* stylelint-disable selector-class-pattern */
@import "src/core/common/assets/styles/mixins";

$slideOffset: 6px;
$slideOffsetDesktop: 17px;
$sliderButtonSize: 24px;
$sliderButtonSizeDesktop: 40px;
$sliderButtonOffset: $sliderButtonSize / 2 + 12px;
$sliderButtonOffsetDesktop: $sliderButtonSizeDesktop / 2 + 12px;

.content {
  display: block;
  text-align: center;
  background-color: transparent;
  padding-top: 0;
  padding-bottom: 0;
}

.title {
  @include contentManagerSectionHeading() {
    margin-bottom: 24px;

    @include respondTo(l) {
      margin-bottom: 40px;
    }
  }
}

.slider {
  @include pageContainerMaxWidth(1085px);

  & :global(.slick-slide) {
    & > div {
      display: block !important;
    }
  }

  & :global(.slick-list) {
    margin-left: -$slideOffset;
    margin-right: -$slideOffset;
    padding-bottom: 48px;

    @include respondTo(l) {
      margin-left: -$slideOffsetDesktop;
      margin-right: -$slideOffsetDesktop;
      padding-bottom: 54px;
    }
  }
}

.slide {
  @include aspectRatio(9, 16);

  max-width: 280px;
  margin-right: $slideOffset;
  margin-left: $slideOffset;


  @include respondTo(l) {
    margin-left: $slideOffsetDesktop;
    margin-right: $slideOffsetDesktop;
  }
}

.playButton {
  background: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" fill="none" height="100%" viewBox="0 0 87 115" width="100%"><path clip-rule="evenodd" d="M83.99 10.81C90.08 21.24 86.62 34.66 76.26 40.79L69.05 45.06L74.17 47.38C81.58 50.74 86.52 57.99 86.96 66.17C87.40 74.34 83.27 82.09 76.26 86.24L32.76 111.97C22.41 118.10 9.08 114.61 3.00 104.18C-3.08 93.75 .37 80.33 10.73 74.20L17.94 69.93L12.82 67.61C5.41 64.25 .47 57.00 .03 48.82C-0.40 40.65 3.72 32.90 10.73 28.75L54.23 3.02C64.58 -3.10 77.91 .38 83.99 10.81Z" fill="%23f00" fill-rule="evenodd"></path><path clip-rule="evenodd" d="M33 74L33 41L61 57.5L33 74Z" fill="white" fill-rule="evenodd"></path></svg>') no-repeat center / 68px 90px
}

.sliderButton {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  bottom: 0;
  width: $sliderButtonSize;
  height: $sliderButtonSize;
  z-index: 1;
  border-radius: 0;

  @include respondTo(l) {
    width: $sliderButtonSizeDesktop;
    height: $sliderButtonSizeDesktop;
  }

  .arrow {
    fill: var(--typography-100);
    width: 24px;
    height: 24px;
  }
}

.prevButton {
  right: 50%;
  transform: translateX(calc(50% - $sliderButtonOffset));

  @include respondTo(l) {
    transform: translateX(calc(50% - $sliderButtonOffsetDesktop));
  }

  .arrow {
    transform: rotate(180deg);
  }
}

.nextButton {
  right: 50%;
  transform: translateX(calc(50% + $sliderButtonOffset));

  @include respondTo(l) {
    transform: translateX(calc(50% + $sliderButtonOffsetDesktop));
  }
}

.author {
  padding-top: 12px;
  display: flex;
  align-items: center;
  margin-right: $slideOffset;
  margin-left: $slideOffset;


  @include respondTo(l) {
    margin-left: $slideOffsetDesktop;
    margin-right: $slideOffsetDesktop;
  }
}

.authorImage {
  @include positionFutureImage(24px, 24px);

  border-radius: 50%;
  overflow: hidden;
  margin-right: 8px;
}

.authorName {
  font-size: 16px;
  font-weight: 700;
  line-height: 150%;
  color: var(--primary);
}