@import "src/core/common/assets/styles/mixins";

.root {
  background: var(--secondary-800);
  position: relative;
  margin-bottom: 32px;

  @include respondTo(l) {
    margin-bottom: 80px;
  }
}

.container {
  @include pageContainerMaxWidth(600px);
  @include sectionSidePaddings();

  padding-top: 10px;
  padding-bottom: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  @include respondTo(l) {
    padding-left: 100px;
    padding-right: 100px;

    @include pageContainerMaxWidth();
  }
}

.sectionLink {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.title {
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  color: var(--primary-100);
  margin-bottom: 4px;

  strong {
    font-weight: 700;
  }

  @include respondTo(l) {
    text-transform: uppercase;
    font-size: 20px;
    line-height: 27px;
    margin-bottom: 0;
  }
}

.appList {
  flex-shrink: 0;
  display: flex;
  align-items: center;
  margin-left: 8px;
  margin-right: -8px;

  @include respondTo(l) {
    height: auto;
    margin-right: -24px;
  }
}

.appListItemDivider {
  display: none;

  @include respondTo(l) {
    display: block;
  }
}

.appListItem {
  padding-right: 8px;

  @include respondTo(l) {
    padding-right: 24px;
  }
}

.appIcon {
  display: inline-block;
  width: 32px;
  height: 32px;

  @include respondTo(l) {
    width: 52px;
    height: 52px;
  }
}

.appDivider {
  font-weight: 800;
  font-size: 25px;

  // TODO: change to palette
  color: #A5A3F3;
}
