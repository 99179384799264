@import "src/core/common/assets/styles/mixins";

.root {
  display: flex;
  flex-direction: column;
  position: relative;
}

.content {
  @include sectionSidePaddings();

  padding-top: 32px;
  padding-bottom: 49px;

  @include respondTo(l) {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px 20px 63px;
  }
}

.title {
  font-weight: 800;
  margin-bottom: 12px;
  font-size: 28px;
  line-height: 38px;

  @include respondTo(l) {
    text-align: center;
    margin-bottom: 16px;
    font-size: 44px;
    line-height: 60px;
  }
}

.titleStart {
  color: var(--typography-100);

  @include respondTo(l) {
    margin-bottom: 4px;
  }
}

.titleEnd {
  color: var(--primary-400);
}

.text {
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
  color: var(--typography-100);
  margin-bottom: 21px;
  max-width: 740px;
  text-align: left;

  @include respondTo(l) {
    margin-bottom: 38px;
    text-align: center;
  }
}

.button {
  width: 100%;
  background: var(--primary);
  color: var(--typography-900);
  font-weight: 600;
  height: 48px;
  justify-content: space-between;
  padding-right: 48px;
  padding-left: 48px;

  @include respondTo(l) {
    font-size: 18px;
    line-height: 21px;
    height: 56px;
  }
}

.buttonWrapper {
  width: 100%;
  max-width: 320px;
  margin: 0 auto;
}

.buttonLabel {
  width: 100%;
}

.icon {
  width: 24px;
  height: 24px;
  color: currentColor;
}

.illustration {
  @include positionFutureImage();
}

.illustrationDesktop {
  @include hideOnMobile();
}

.illustrationMobile {
  @include hideOnDesktop();
}
