@import "src/core/common/assets/styles/mixins";

.content {
  @include pageContainerMaxWidth();
  @include sectionSidePaddings();

  display: flex;
  flex-direction: column;
  align-items: center;

  @include respondTo(l) {
    flex-direction: row;
    padding-left: 100px;
    padding-right: 100px;
  }
}

.title {
  height: 100%;
  font-size: 24px;
  line-height: 34px;
  text-align: center;
  color: var(--primary-100);
  margin-bottom: 32px;
  font-weight: 700;
  padding-left: 15px;
  padding-right: 15px;
  border-top-left-radius: 12px;
  border-bottom-left-radius: 12px;

  @include respondTo(l) {
    font-size: 20px;
    line-height: 28px;
    padding-top: 57px;
    padding-bottom: 57px;
    margin-right: 34px;
    margin-bottom: 0;
    min-width: 204px;
  }
}

.partners {
  @include positionFutureImage();
}

.partnersMobile {
  @include hideOnDesktop();
}

.partnersDesktop {
  @include hideOnMobile();
}
