@import "src/core/common/assets/styles/mixins";

.root {
  background: var(--white-with-opacity-5);
  margin-left: 20px;
  margin-right: 20px;
  border-radius: 20px;
}

.content {
  @include pageContainerMaxWidth();
  @include sectionSidePaddings();

  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 32px;
  padding-bottom: 32px;

  @include respondTo(l) {
    flex-direction: row;
    padding: 60px 100px;
  }
}

.description {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 400px;
  width: 100%;

  @include respondTo(l) {
    margin-right: 118px;
    align-items: flex-start;
  }
}

.title {
  font-weight: 700;
  font-size: 28px;
  line-height: 33px;
  color: var(--typography-100);
  text-align: center;
  margin-bottom: 8px;

  @include respondTo(l) {
    text-align: left;
    font-size: 40px;
    line-height: 145%;
  }
}

.text {
  font-weight: 400;
  font-size: 14px;
  line-height: 145%;
  text-align: center;
  color: var(--typography-200);
  margin-bottom: 24px;

  @include respondTo(l) {
    text-align: left;
    font-size: 18px;
    line-height: 145%;
  }
}

.advantageList {
  flex-grow: 1;
  margin-bottom: -24px;
  max-width: 600px;

  @include respondTo(l) {
    margin-bottom: -35px;
    max-width: none;
  }
}

.advantageListItem {
  padding-bottom: 24px;

  @include respondTo(l) {
    padding-bottom: 35px;
  }
}

.advantage {
  display: flex;

  @include respondTo(l) {
    align-items: center;
  }
}

.advantageIcon {
  margin-right: 16px;

  @include positionFutureImage(48px);
}

.advantageText {
  font-size: 14px;
  line-height: 24px;
  color: var(--typography-100);

  @include respondTo(l) {
    font-weight: 500;
    font-size: 18px;
    line-height: 140%;
  }
}
