@import "src/core/common/assets/styles/mixins";

.content {
  @include pageContainerMaxWidth();
  @include sectionSidePaddings();

  display: flex;
  flex-direction: column;
  align-items: center;

  @include respondTo(l) {
    padding-left: 100px;
    padding-right: 100px;
  }
}

.title {
  font-weight: 700;
  font-size: 28px;
  line-height: 33px;
  color: var(--typography-100);
  margin-bottom: 32px;

  @include respondTo(l) {
    font-size: 40px;
    line-height: 47px;
    margin-bottom: 60px;
  }
}

.answerListWrapper {
  margin-bottom: 32px;
  max-width: 460px;
  margin-left: 0;
  margin-right: 0;

  @include respondTo(l) {
    margin-bottom: 48px;
    max-width: none;
    margin-left: -10px;
    margin-right: -10px;
  }
}

.answerList {
  @include forceTheme(l);
  @include pageContainerMaxWidth(1166px);

  counter-reset: answers-counter;
  display: flex;
  flex-direction: column;

  @include respondTo(l) {
    flex-direction: row;
  }
}

.answerListItem {
  display: flex;
  width: 100%;
  margin-bottom: 16px;
  padding-left: 0;
  padding-right: 0;

  @include respondTo(l) {
    width: 25%;
    margin-bottom: 0;
    padding-left: 10px;
    padding-right: 10px;
  }

  .answer {
    border-radius: 8px;
    padding: 0 16px 18px;
    position: relative;
    counter-increment: answers-counter;

    @include respondTo(l) {
      padding: 0 16px 16px;
    }

    &::before {
      position: absolute;
      content: counter(answers-counter);
      left: 20px;
      top: 16px;
      font-weight: 800;
      font-size: 24px;
      line-height: 120%;
      color: var(--primary);

      @include respondTo(l) {
        font-weight: 800;
        top: 16px;
        left: 16px;
      }
    }
  }

  &:nth-child(even) .answer {
    background-color: var(--star-900);
  }

  &:nth-child(odd) .answer {
    background: var(--tertiary-900);
  }

  &:nth-child(4) .answer {
    background-color: var(--success-100);
  }

  .answerTitle {
    margin-right: auto;
    font-weight: 700;
    font-size: 18px;
    line-height: 25px;
    padding-top: 18px;
    padding-left: 24px;
    color: var(--typography-100);
    margin-bottom: 10px;

    @include respondTo(l) {
      font-size: 20px;
      line-height: 27px;
      padding-left: 0;
      padding-top: 61px;
    }
  }

  .answerText {
    font-size: 14px;
    line-height: 21px;
    color: var(--typography-200);

    @include respondTo(l) {
      font-size: 14px;
      line-height: 19px;
    }
  }
}

.button {
  height: 50px;
  font-weight: 600;
  font-size: 18px;
  line-height: 21px;
  background: var(--primary);
  color: var(--typography-900);
  width: 100%;
}

.buttonWrapper {
  width: 100%;
  max-width: 320px;
  margin-top: 0;
  margin-bottom: 0;
}
